<template>
  <div>

    <h1 v-if="!isFetchingFaqEdit && !faqEdit">
      Aucun établissement ne correspond à cet enregistrement
    </h1>
    <content-loader
      v-if="isFetchingFaqEdit"
      view-box="0 0 400 460"
      :speed="2"
      primary-color="#f3f3f3"
      secondary-color="#ecebeb"
    >
      <circle
        cx="29"
        cy="30"
        r="17"
      />
      <rect
        x="58"
        y="18"
        rx="2"
        ry="2"
        width="140"
        height="10"
      />
      <rect
        x="58"
        y="34"
        rx="2"
        ry="2"
        width="140"
        height="10"
      />
      <rect
        x="-7"
        y="60"
        rx="2"
        ry="2"
        width="408"
        height="132"
      />
    </content-loader>
    <div v-if="faqEdit">
      <b-card
        class="blog-edit-wrapper"
      >
        <!-- form -->
        <!-- -------- {{ faqEdit.roles }} -->
        <b-form
          class="uth-register-form mt-2"
          @submit.prevent="save"
        >
          <b-row>
            <!-- Titre -->
            <b-col md="6">
              <b-form-group
                label="Titre"
                label-for="blog-edit-title"
                class="mb-2"
              >
                <b-form-input
                  id="blog-edit-title"
                  v-model="faqEdit.titre"
                />
              </b-form-group>
            </b-col>
            <!-- Cible / Catégorie -->
            <!-- <b-col md="6">
              <b-form-group
                label="Cible / Catégorie"
                label-for="blog-edit-category"
                class="mb-2"
              >
                <v-select
                  id="blog-edit-category"
                  v-model="faqEdit.cibleCategorie"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  multiple
                  :options="categoryOption"
                />
              </b-form-group>
            </b-col> -->
            <!-- Cible / Catégorie -->
            <b-col md="6">
              <b-form-group
                label="Cible / Catégorie"
                label-for="blog-edit-roles"
                class="mb-2"
              >   
                <v-select
                  v-model="faqEdit.roles"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  multiple
                  label="nom"
                  :options="roles"
                  :reduce="item=>item._id"
                />
              </b-form-group>
            </b-col>
            <!-- Contenu / Description -->
            <b-col cols="12">
              <b-form-group
                label="Contenu / Description"
                label-for="blog-content"
                class="mb-2"
              >
                <quill-editor
                  id="blog-content"
                  v-model="faqEdit.contenu"
                  :options="snowOption"
                />
              </b-form-group>
            </b-col>
            <!-- Bannière -->
            <!-- {{ faqEdit.banniere[0] }} -->
            <b-col
              cols="12"
              class="mb-2"
            >
              <div class="border rounded p-2">
                <h4 class="mb-1">
                  Bannière
                </h4>
                <FileWidget
                  :type="'image'"
                  :file="faqEdit.banniere[0]"
                  :b-img-props="{ class:'card-img-top', width:'600', height:'500',}"
                />
                <b-media
                  no-body
                  vertical-align="center"
                  class="flex-column flex-md-row mt-1"
                >
                  <!-- new img -->
                  <b-media-aside>
                    <b-img
                      ref="refPreviewEl"
                      :src="faqEdit.banniere[0]"
                      height="110"
                      width="170"
                      class="rounded mr-2 mb-1 mb-md-0"
                    />
                  </b-media-aside>
                  <!-- new img input -->
                  <!--  -->
                  <b-media-body class="mt-2">
                    <small class="text-muted">Résolution d'image requis 800x400, Taille de l'image 2MB.</small>
                    <b-form-file
                      ref="refInputEl"
                      v-model="faqEdit.banniere[0]"
                      accept=".jpg, .png, .gif"
                      placeholder="Choisir un fichier"
                      @input="inputImageRenderer"
                    />
                  </b-media-body>
                </b-media>
              </div>
            </b-col>
            <!-- Boutton d'enregistrement -->
            <b-col
              cols="12"
              class="mt-50 text-right"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-1"
                type="submit"
                :disabled="isLoading"
              >
                <b-spinner
                  v-if="isLoading"
                  small
                />
                <span v-if="!isLoading">
                  Mettre à jour
                </span>

              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="outline-secondary"
              >
                Annuler
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      <!--/ form -->
      </b-card>
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BMedia,
  // BAvatar,
  BCardText,
  BMediaAside,
  BMediaBody,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BImg,
  BFormFile,
  BLink,
  BButton,
  BSpinner,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { quillEditor } from 'vue-quill-editor'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import localstorageService from '@/services/localstorage/localstorage.service'
import Ripple from 'vue-ripple-directive'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'
import { mapActions } from 'vuex'
import { ContentLoader } from 'vue-content-loader'
import FileWidget from '@/components/FileWidget.vue'

import filesService from '@/services/http/files.service'

// import du store
import faqStoreModule from '@/store/faq'
import paramsStoreModule from "@/store/params";

import {
  registerStoreModule,
  unregisterStoreModule,
} from '@/helpers/vuex-utils'
import { off } from 'process'

export default {
  components: {
    BCard,
    BMedia,
    // BAvatar,
    BCardText,
    BMediaAside,
    BMediaBody,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    BSpinner,
    vSelect,
    quillEditor,
    ContentLoader,
    FileWidget,
  },
  directives: {
    Ripple,
  },
  setup() {
    const refInputEl = ref("refInputEl")
    const refPreviewEl = ref("refPreviewEl")

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, base64 => {
      refPreviewEl.value.src = base64
    })

    // import du store
    const requiredStoreModules = [
      { path: 'faq', module: faqStoreModule },
      { path: "params", module: paramsStoreModule },
    ]
    // Register module
    registerStoreModule(requiredStoreModules)

    return {
      refInputEl,
      refPreviewEl,
      inputImageRenderer,
      requiredStoreModules,
    }
  },
  data() {
    return {
      // faqEdit: {
      //   titre: '',
      //   cibleCategorie: '',
      //   status: 'Publié',
      //   contenu: null,
      //   banniere: null,
      // },
      categoryOption: ['Super Utilisateur', 'Agent du MTCA', 'Agent du gouvernement', "Promoteur d'établissement", "Agent d'établissement", 'Agent frontalier', 'Grand Public'],
      // statusOption: ['Publié', 'En attente', 'Brouillon'],
      snowOption: {
        theme: 'snow',
      },
      isLoading: false,
      isFetchingFaqEdit: false,
      faqEdit: null,
      faqId: null,
      banniereName: null,

      roles: []
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler(val, old) {
        this.isFetchingFaqEdit = true
        const { faqId } = val.params
        this.faqId = faqId
        this.getFaq(faqId).then(res => {
            this.faqEdit = res.data.resource
            this.isFetchingFaqEdit = false


            // clear permissions
            this.faqEdit.roles = this.faqEdit.roles.map(e => e._id)
            // if an image
            // if(this.faqEdit.banniere[0]) {
            //   this.banniereName = this.faqEdit.banniere[0]
            //   // this.downlodFile(this.faqEdit.banniere[0])
            // }
          })
          .catch(err => {
            this.isFetchingFaqEdit = false
          })
      },
    },
  },
  beforeDestroy() {
    unregisterStoreModule(this.requiredStoreModules)
  },

  async mounted () {
    // load roles ----------------------------------------- 
    const userRoleReq = await this.action_fetchTypesRoles().catch(err => {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: err.message || 'Une erreur est survenue',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    })
    const { resources } = userRoleReq.data
    if(resources) this.roles = resources
  },

  methods: {
    ...mapActions('faq', {
      getFaq: 'getFaq',
      editFaq: 'editFaq',
    }),
    ...mapActions('params', { action_fetchTypesRoles: 'fetchTypesRoles' }),
    // ------------------------------------------------------------------------
    async save() {
      if (this.isLoading) {
        return
      }

      this.isLoading = true
      const formData = new FormData()
      const objectdata = {
        titre: this.faqEdit.titre,
        contenu: this.faqEdit.contenu,
        roles: this.faqEdit.roles.length > 0 ? this.faqEdit.roles.join() : []
      }
      formData.append('objectdata', JSON.stringify(objectdata))
      if( this.faqEdit.banniere[0] instanceof File ){
        formData.append('fileprop', 'banniere')
        formData.append('filenumber', 1)
        const file = this.faqEdit.banniere[0]
        formData.append('filedata0', file)
      }
      
      // for (const pair of formData.entries()) {
      //   console.log(`${pair[0]} - ${pair[1]}`)
      // }
      
      // return

      this.editFaq({ id: this.faqId, data: formData })
        .then(result => {
          console.log('result: ', result)

          // return
          
          this.isLoading = false
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Mise à jour réussie',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          if (localstorageService.getUserId()) {
            this.$router.replace({ name: 'faq-detail' })
          } else {
            this.$router.replace({ name: 'auth-login' })
          }
        })
        .catch(err => {
          this.isLoading = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: err.message || 'Une erreur est survenue',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/quill.scss';
@import '@core/scss/vue/pages/page-blog.scss';
</style>
